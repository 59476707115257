import React, { useEffect } from 'react'

import { App, URLOpenListenerEvent, BackButtonListenerEvent } from '@capacitor/app'

import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Stack, CircularProgress } from '@mui/material'

import AuthPage from './pages/Auth/Auth'
import LoginPage from './pages/Auth/Login'
import RegisterPage from './pages/Auth/Register'
import ForgotPassword from './pages/Auth/ForgotPassword'
import NewPassword from './pages/Auth/NewPassword'

import MapPage from './pages/Map'
import StationsPage from './pages/Stations'
import StationPage from './pages/Station'
import ChargingPointPage from './pages/ChargingPoint'
import WalletPage from './pages/Wallet'
import ProfilePage from './pages/Profile'

import AuthTemplate from './template/AuthTemplate'
import MainTemplate from './template/MainTemplate'

import { KeyboardProvider } from '@/contexts/KeyboardContext'
import { StationModalProvider } from '@/contexts/StationModalContext'

import { useAuth } from '@/hooks/useAuth'

import ErrorHandler from './components/ErrorHandler'

const ProtectedRoute: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size="5rem" />
      </Stack>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" replace state={{ from: location }} />
  }

  return <Outlet />
}

const AppUrlListener: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.app').pop()

      if (slug) {
        navigate(slug)
      }
    })

    App.addListener('backButton', (_: BackButtonListenerEvent) => {})

    return () => {
      App.removeAllListeners()
    }
  }, [navigate, location.key])

  return null
}

const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <StationModalProvider>
        <KeyboardProvider>
          <Routes>
            <Route path="/" element={<MainTemplate />}>
              <Route path="auth" element={<AuthTemplate />}>
                <Route index element={<AuthPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="new-password" element={<NewPassword />} />
              </Route>

              <Route index element={<MapPage />} />
              <Route path="stations" element={<StationsPage />} />
              <Route path="station/:stationId" element={<StationPage />} />

              <Route element={<ProtectedRoute />}>
                <Route path="station/:stationId/charging-point/:chargingPointId" element={<ChargingPointPage />} />
                <Route path="wallet" element={<WalletPage />} />
                <Route path="profile" element={<ProfilePage />} />
              </Route>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </KeyboardProvider>
      </StationModalProvider>
      <AppUrlListener />
      <ErrorHandler />
    </BrowserRouter>
  )
}

export default Pages
