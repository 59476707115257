import React from 'react'

import { Stack, Box, Typography, Divider, Skeleton } from '@mui/material'
import { PendingOutlined, TimerOutlined, DoneOutlined, DeviceUnknownOutlined } from '@mui/icons-material'

import { useQueryChargingHistory } from '@/api/charging/queries'

import { ChargingHistory } from '@/types/Charging'

const HistoryItem: React.FC<{
  history: ChargingHistory
  onClick?: (id: number) => void
}> = ({ history, onClick }) => {
  const getStatusColor = (status: ChargingHistory['status']) => {
    switch (status) {
      case 'NOT_STARTED':
        return 'primary.main'
      case 'IN_PROGRESS':
        return 'secondary.main'
      case 'FINISHED':
        return 'success.main'
      default:
        return 'error.main'
    }
  }

  const getStatusIcon = (status: ChargingHistory['status']) => {
    switch (status) {
      case 'NOT_STARTED':
        return <PendingOutlined />
      case 'IN_PROGRESS':
        return <TimerOutlined />
      case 'FINISHED':
        return <DoneOutlined />
      default:
        return <DeviceUnknownOutlined />
    }
  }

  const getStatusLabel = (status: ChargingHistory['status']) => {
    switch (status) {
      case 'NOT_STARTED':
        return 'Aguardando'
      case 'IN_PROGRESS':
        return 'Em andamento'
      case 'FINISHED':
        return 'Finalizado'
      default:
        return 'Desconhecido'
    }
  }

  const getElapsedTime = (start: Date, end: Date) => {
    const elapsed = end.getTime() - start.getTime()
    const hours = Math.floor(elapsed / 1000 / 60 / 60)
    const minutes = Math.floor((elapsed / 1000 / 60) % 60)

    return `${hours}h ${minutes}m`
  }

  return (
    <Box
      key={history.id}
      sx={{
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        overflow: 'hidden',
        borderRadius: 4,
        height: 150,
      }}
    >
      <Stack direction="column" height="100%" overflow="hidden" borderRadius={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          bgcolor={getStatusColor(history.status)}
        >
          <Typography color="white" variant="subtitle2" fontWeight="bold">
            {getStatusLabel(history.status)}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          fontSize="1rem"
          textAlign="center"
          padding={0.5}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {history.station_name} - {history.charging_point_title}
        </Typography>
        <Divider />
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} paddingX={2} flex={1}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: getStatusColor(history.status),
              color: 'white',
            }}
          >
            {getStatusIcon(history.status)}
          </Box>
          <Divider orientation="vertical" flexItem />
          <Stack direction="column" alignItems="flex-start" justifyContent="center" height="100%" spacing={1} flex={1}>
            <Stack direction="column">
              <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
                <b>Início:</b>{' '}
                {new Date(history.start_time).toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
                {' às '}
                {new Date(history.start_time).toLocaleString('pt-BR', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Typography>
              {history.end_time ? (
                <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
                  <b>Tempo de recarga:</b> {getElapsedTime(new Date(history.start_time), new Date(history.end_time))}
                </Typography>
              ) : null}
              <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
                <b>Consumo:</b> {history.kwh_delivered.toFixed(2)} kWh
              </Typography>
              <Typography variant="body2" fontSize="0.9rem" color="textSecondary">
                <b>Custo total:</b>{' '}
                {Number(history.total_cost).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

const HistoryTab: React.FC = () => {
  const { data: history, isLoading: isLoadingHistory, isError: isErrorHistory } = useQueryChargingHistory()

  return (
    <React.Fragment>
      {isLoadingHistory ? (
        <Stack spacing={2} width="100%">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={150} sx={{ borderRadius: 4 }} />
          ))}
        </Stack>
      ) : isErrorHistory ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/server_down.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Ocorreu um erro.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Não foi possível carregar o histórico de recargas.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      ) : history?.length ? (
        <Stack spacing={2} width="100%">
          {history.map((historyItem: ChargingHistory) => (
            <HistoryItem key={historyItem.id} history={historyItem} />
          ))}
        </Stack>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Stack direction="column" alignItems="center" spacing={4}>
            <Box component="img" src="/assets/png/empty.png" width="100%" maxWidth={300} />
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h6" color="textSecondary">
                Ops! Parece que está vazio.
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Não há recargas realizadas.
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </React.Fragment>
  )
}

export default HistoryTab
