import React from 'react'

import { Card, CardContent, Box, Stack, Typography, Divider } from '@mui/material'
import { EvStationOutlined, ElectricalServicesOutlined, BoltOutlined, AttachMoneyOutlined } from '@mui/icons-material'

import { ChargingPoint, ChargingPointStatus } from '@/types/Station'

interface ChargingPointItemProps {
  chargingPoint: ChargingPoint
  onClick: (chargingPoint: ChargingPoint) => void
}

const ChargingPointItem: React.FC<ChargingPointItemProps> = ({ chargingPoint, onClick }) => {
  const getChargingPointStatusColor = (status: ChargingPointStatus) => {
    switch (status) {
      case ChargingPointStatus.AVAILABLE:
        return 'success.main'
      case ChargingPointStatus.CONNECTED:
        return 'info.main'
      case ChargingPointStatus.IN_PROGRESS:
        return 'warning.main'
      case ChargingPointStatus.UNAVAILABLE:
        return 'error.main'
      case ChargingPointStatus.MAINTENANCE:
        return 'error.main'
      default:
        return 'text.primary'
    }
  }

  const getChargingPointStatus = (status: ChargingPointStatus) => {
    switch (status) {
      case ChargingPointStatus.AVAILABLE:
        return 'Disponível'
      case ChargingPointStatus.CONNECTED:
        return 'Conectado'
      case ChargingPointStatus.IN_PROGRESS:
        return 'Carregando'
      case ChargingPointStatus.UNAVAILABLE:
        return 'Indisponível'
      case ChargingPointStatus.MAINTENANCE:
        return 'Em manutenção'
      default:
        return 'Desconhecido'
    }
  }

  const getConectorIcon = (connectorType: string) => {
    switch (connectorType) {
      case 'TYPE_TWO':
        return '/assets/svg/connectors/connector-plug-type-two.svg'
      case 'CSS_TYPE_TWO':
        return '/assets/svg/connectors/connector-plug-css-type-two.svg'
      case 'TESLA':
        return '/assets/svg/connectors/connector-plug-tesla.svg'
      default:
        return '/assets/icons/unknown.svg'
    }
  }

  return (
    <Card variant="outlined" sx={{ cursor: 'pointer', borderRadius: 4 }} onClick={() => onClick(chargingPoint)}>
      <CardContent>
        <Stack direction="column" flex={1} gap={1} maxWidth="100%">
          <Stack direction="column">
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {chargingPoint.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {chargingPoint.description}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="column">
            <Stack
              color={getChargingPointStatusColor(chargingPoint.status)}
              direction="row"
              alignItems="center"
              spacing={0.5}
            >
              <EvStationOutlined />
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                <b>Status:</b> {getChargingPointStatus(chargingPoint.status)}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <ElectricalServicesOutlined />
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.secondary"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                Tipo de Conector(es):
              </Typography>
              {chargingPoint.charging_connectors.map((connector) => (
                <Box
                  component="img"
                  key={connector.connector_type}
                  src={getConectorIcon(connector.connector_type)}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
              ))}
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <BoltOutlined />
              <Typography
                variant="body1"
                color="text.secondary"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                <b>Potência:</b> {chargingPoint.power} kW
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <AttachMoneyOutlined />
              <Typography
                variant="body1"
                color="text.secondary"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                <b>Valor (kWh):</b> R$ {chargingPoint.rate_per_kwh}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ChargingPointItem
