import http from '@/api/http'

import {
  PostAuthLoginPayload,
  PostAuthLoginResponse,
  PostAuthLogoutPayload,
  PostAuthLogoutResponse,
  PostAuthRefreshPayload,
  PostAuthRefreshResponse,
  PostAuthRegisterPayload,
  PostAuthRegisterResponse,
  GetAuthLoginSsoPayload,
  GetAuthLoginSsoResponse,
  PostAuthLoginSsoPayload,
  PostAuthLoginSsoResponse,
  PostAuthPasswordResetPayload,
  PostAuthPasswordResetResponse,
  PostAuthPasswordResetConfirmPayload,
  PostAuthPasswordResetConfirmResponse,
  PutAuthPasswordResetPayload,
  PutAuthPasswordResetResponse,
} from './types'

export const postAuthLogin = async (payload: PostAuthLoginPayload): Promise<PostAuthLoginResponse> => {
  const { username, password } = payload

  const response = await http.post<PostAuthLoginResponse, PostAuthLoginPayload>('/auth/login/', { username, password })
  return response.data
}

export const postAuthLogout = async (payload: PostAuthLogoutPayload): Promise<PostAuthLogoutResponse> => {
  const { refresh } = payload

  const response = await http.post<PostAuthLogoutResponse, PostAuthLogoutPayload>('/auth/logout/', { refresh })
  return response.data
}

export const postAuthRefresh = async (payload: PostAuthRefreshPayload): Promise<PostAuthRefreshResponse> => {
  const { refresh } = payload

  const response = await http.post<PostAuthRefreshResponse, PostAuthRefreshPayload>('/auth/refresh/', { refresh })
  return response.data
}

export const postAuthRegister = async (payload: PostAuthRegisterPayload): Promise<PostAuthRegisterResponse> => {
  const { first_name, last_name, cpf, phone, email, password } = payload

  const response = await http.post<PostAuthRegisterResponse, PostAuthRegisterPayload>('/auth/register/', {
    first_name,
    last_name,
    cpf,
    phone,
    email,
    password,
  })
  return response.data
}

export const getAuthLoginSso = async (payload: GetAuthLoginSsoPayload): Promise<GetAuthLoginSsoResponse> => {
  const { broker } = payload

  const response = await http.get<GetAuthLoginSsoResponse, GetAuthLoginSsoPayload>('/auth/login/sso/', { broker })
  return response.data
}

export const postAuthLoginSso = async (payload: PostAuthLoginSsoPayload): Promise<PostAuthLoginSsoResponse> => {
  const { code } = payload

  const response = await http.post<PostAuthLoginSsoResponse, PostAuthLoginSsoPayload>('/auth/login/sso/', { code })
  return response.data
}

export const postAuthPasswordReset = async (
  payload: PostAuthPasswordResetPayload
): Promise<PostAuthPasswordResetResponse> => {
  const { username } = payload

  const response = await http.post<PostAuthPasswordResetResponse, PostAuthPasswordResetPayload>(
    '/auth/password/reset/',
    { username }
  )
  return response.data
}

export const postAuthPasswordResetConfirm = async (
  payload: PostAuthPasswordResetConfirmPayload
): Promise<PostAuthPasswordResetConfirmResponse> => {
  const { username, pin } = payload

  const response = await http.post<PostAuthPasswordResetConfirmResponse, PostAuthPasswordResetConfirmPayload>(
    '/auth/password/reset/confirm/',
    { username, pin }
  )
  return response.data
}

export const putAuthPasswordReset = async (
  payload: PutAuthPasswordResetPayload
): Promise<PutAuthPasswordResetResponse> => {
  const { username, pin, password } = payload

  const response = await http.put<PutAuthPasswordResetResponse, PutAuthPasswordResetPayload>('/auth/password/reset/', {
    username,
    pin,
    password,
  })
  return response.data
}
