import React, { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '@/hooks/useAuth'

import { useToast } from '@/contexts/ToastContext'

const ErrorHandler: React.FC = () => {
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()
  const { logout } = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const error = params.get('error')

    if (error) {
      let message = 'Ocorreu um erro desconhecido.'

      switch (error) {
        case 'unauthorized':
          message = 'Você não está autenticado. Faça login para continuar.'

          logout()

          break
        case 'forbidden':
          message = 'Acesso proibido. Você não tem permissão para esta ação.'
          break
        default:
          message = decodeURIComponent(error)
          break
      }

      toast.error(message)
      params.delete('error')

      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      )
    }
  }, [location, navigate, toast])

  return null
}

export default ErrorHandler
