import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { HttpError } from '@/api/http'

import { getPaymentCredits, getPaymentHistory } from './services'
import { GetPaymentCreditsResponse, GetPaymentHistoryResponse } from './types'

export const useQueryPaymentCredits = (
  options?: Omit<UseQueryOptions<GetPaymentCreditsResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-credits']
  const queryFn = () => getPaymentCredits()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryPaymentHistory = (
  options?: Omit<UseQueryOptions<GetPaymentHistoryResponse, HttpError>, 'queryKey' | 'queryFn'>
) => {
  const queryKey: QueryKey = ['payment-history']
  const queryFn = () => getPaymentHistory()

  return useQuery({
    queryKey,
    queryFn,
    ...options,
  })
}
