import React, { useState } from 'react'

import { Box, Stack, Typography, Divider, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import HistoryTab from '@/modules/Wallet/HistoryTab'
import WalletTab from '@/modules/Wallet/WalletTab'
import CardsTab from '@/modules/Wallet/CardsTab'

const WalletPage: React.FC = () => {
  const [value, setValue] = useState('history')

  const handleOnChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Stack direction="column" paddingTop="24px" paddingX="24px" minHeight="100vh">
      <Stack
        direction="column"
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
          flex: 1,
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Carteira
          </Typography>
        </Stack>
        <Divider sx={{ marginTop: 2 }} />
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList variant="fullWidth" onChange={handleOnChange}>
              <Tab label="Histórico" value="history" />
              <Tab label="Carteira" value="wallet" />
              <Tab label="Cartões" value="cards" disabled />
            </TabList>
          </Box>
          <TabPanel
            value="history"
            sx={
              value === 'history'
                ? {
                    display: 'flex',
                    padding: 0,
                    flex: 1,
                    marginTop: 2,
                  }
                : {}
            }
          >
            <HistoryTab />
          </TabPanel>
          <TabPanel
            value="wallet"
            sx={
              value === 'wallet'
                ? {
                    display: 'flex',
                    padding: 0,
                    flex: 1,
                    marginTop: 2,
                  }
                : {}
            }
          >
            <WalletTab />
          </TabPanel>
          <TabPanel
            value="cards"
            sx={
              value === 'cards'
                ? {
                    display: 'flex',
                    padding: 0,
                    flex: 1,
                    marginTop: 2,
                  }
                : {}
            }
          >
            <CardsTab />
          </TabPanel>
        </TabContext>
      </Stack>
    </Stack>
  )
}

export default WalletPage
