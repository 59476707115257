import React from 'react'

import { Capacitor } from '@capacitor/core'
import { AppLauncher } from '@capacitor/app-launcher'

import { useNavigate } from 'react-router-dom'

import { Slide, Box, Stack, Button, Divider, Typography } from '@mui/material'
import { KeyboardArrowDown, EvStationOutlined, ElectricalServicesOutlined, BoltOutlined } from '@mui/icons-material'

import { ChargingPointStatus } from '@/types/Station'

import { StationModalProps } from './types'

const StationModal: React.FC<StationModalProps> = ({ station, onClose }) => {
  const navigate = useNavigate()

  const someChargingPointAvailable = station?.charging_points.some(
    (chargingPoint) => chargingPoint.status === ChargingPointStatus.AVAILABLE
  )
  const allChargingPointsInMaintenance = station?.charging_points.every(
    (chargingPoint) => chargingPoint.status === ChargingPointStatus.MAINTENANCE
  )

  const status = someChargingPointAvailable
    ? 'Disponível'
    : allChargingPointsInMaintenance
      ? 'Em manutenção'
      : 'Ocupado'

  const connectorTypesSet = new Set<string>()
  station?.charging_points.forEach((cp) => {
    cp.charging_connectors.forEach((connector) => {
      connectorTypesSet.add(connector.connector_type)
    })
  })

  const powersSet = new Set<number>()
  station?.charging_points.forEach((cp) => {
    powersSet.add(cp.power)
  })
  const powers = Array.from(powersSet).map((power) => `${power} kW`)
  const powersString =
    powers.length > 0
      ? powers.reduce((acc, power, index) => acc + (index === powers.length - 1 ? ' e ' : ', ') + power)
      : 'Desconhecida'

  const getConectorIcon = (connectorType: string) => {
    switch (connectorType) {
      case 'TYPE_TWO':
        return '/assets/svg/connectors/connector-plug-type-two.svg'
      case 'CSS_TYPE_TWO':
        return '/assets/svg/connectors/connector-plug-css-type-two.svg'
      case 'TESLA':
        return '/assets/svg/connectors/connector-plug-tesla.svg'
      default:
        return '/assets/icons/unknown.svg'
    }
  }

  const handleOnClickViewDetails = () => {
    navigate(`/station/${station?.id}`)
  }

  const handleOnClickTraceRoute = async () => {
    const destination = `${station?.latitude},${station?.longitude}`

    let url = ''
    if (Capacitor.getPlatform() === 'ios') {
      url = `maps://?daddr=${destination}`
    } else if (Capacitor.getPlatform() === 'android') {
      url = `geo:${destination}?q=${destination}`
    } else {
      url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`
    }

    try {
      await AppLauncher.openUrl({ url })
    } catch (error) {
      window.open(url, '_blank')
    }
  }

  return (
    <Box
      position="fixed"
      sx={{
        bottom: '112px',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingTop: 'env(safe-area-inset-top)',
        paddingX: '24px',
        width: '100%',
        zIndex: 1,
      }}
    >
      <Slide direction="up" in={!!station} mountOnEnter unmountOnExit>
        <Stack
          direction="column"
          borderRadius={4}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(5px)',
          }}
        >
          <Button variant="text" color="primary" onClick={onClose}>
            <KeyboardArrowDown />
          </Button>
          <Divider />
          <Stack direction="column" spacing={2} paddingY={1}>
            <Stack direction="column" spacing={1}>
              <Stack direction="column" alignItems="flex-start" paddingX={2}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  maxWidth="100%"
                >
                  {station?.name}
                </Typography>
                {station?.description && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    maxWidth="100%"
                  >
                    {station?.description}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  maxWidth="100%"
                >
                  {station?.address}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  maxWidth="100%"
                >
                  {station?.city} - {station?.state}, {station?.zip_code}
                </Typography>
              </Stack>
              <Divider />
              <Stack direction="column" alignItems="flex-start" paddingX={2}>
                <Stack
                  color={someChargingPointAvailable ? 'success.main' : 'error.main'}
                  direction="row"
                  alignItems="center"
                  maxWidth="100%"
                >
                  <EvStationOutlined />
                  <Typography variant="body1">
                    <b>Status:</b> {status}
                  </Typography>
                </Stack>

                {station && station.charging_points.length > 0 ? (
                  <React.Fragment>
                    <Stack direction="row" alignItems="center" maxWidth="100%">
                      <ElectricalServicesOutlined />
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.secondary"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        Tipo de Conector(es):
                      </Typography>
                      {Array.from(connectorTypesSet).map((connectorType) => (
                        <Box
                          component="img"
                          key={connectorType}
                          src={getConectorIcon(connectorType)}
                          sx={{ width: 24, height: 24, marginLeft: 1 }}
                        />
                      ))}
                    </Stack>

                    <Stack direction="row" alignItems="center" maxWidth="100%">
                      <BoltOutlined />
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        <b>Potência(s):</b> {powersString}
                      </Typography>
                    </Stack>
                  </React.Fragment>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} padding={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnClickViewDetails}
              disableElevation
              sx={{ borderRadius: 4 }}
            >
              <Typography variant="body2">Ver Detalhes</Typography>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOnClickTraceRoute}
              disableElevation
              sx={{ borderRadius: 4 }}
            >
              <Typography variant="body2">Traçar Rota</Typography>
            </Button>
          </Stack>
        </Stack>
      </Slide>
    </Box>
  )
}

export default StationModal
