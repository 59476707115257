import React from 'react'

import { Capacitor } from '@capacitor/core'
import { AppLauncher } from '@capacitor/app-launcher'

import { useParams, useNavigate } from 'react-router-dom'

import { Stack, Box, Typography, Divider, Button, Skeleton } from '@mui/material'
import { LocalPhoneOutlined, EmailOutlined } from '@mui/icons-material'

import { useQueryStation } from '@/api/stations/queries'
import { ChargingPoint } from '@/types/Station'

import ChargingPointItem from '@/modules/Station/ChargingPointItem'

const StationPage: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()

  const stationId = params.stationId as string

  const { data: station, isLoading: isStationLoading } = useQueryStation(
    {
      id: Number(stationId),
    },
    {
      enabled: Boolean(stationId),
      refetchInterval: 5000,
      retry: false,
    }
  )

  const handleOnClickTraceRoute = async () => {
    const destination = `${station?.latitude},${station?.longitude}`

    let url = ''
    if (Capacitor.getPlatform() === 'ios') {
      url = `maps://?daddr=${destination}`
    } else if (Capacitor.getPlatform() === 'android') {
      url = `geo:${destination}?q=${destination}`
    } else {
      url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`
    }

    try {
      await AppLauncher.openUrl({ url })
    } catch (error) {
      window.open(url, '_blank')
    }
  }

  const handleOnClickViewChargingPoint = (chargingPoint: ChargingPoint) => {
    navigate(`/station/${stationId}/charging-point/${chargingPoint.id}`)
  }

  return (
    <Stack paddingTop="24px" paddingX="24px">
      <Stack
        direction="column"
        spacing={2}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Detalhes da Estação
          </Typography>
        </Stack>
        <Divider />
        {isStationLoading ? (
          <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: 4 }} />
        ) : station?.image ? (
          <Box
            component="img"
            src={station?.image}
            alt={station?.name}
            width="100%"
            height={200}
            sx={{ objectFit: 'cover', borderRadius: 4 }}
          />
        ) : null}
        <Stack direction="column" spacing={1}>
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography variant="h5" fontWeight="bold">
              {station?.name}
            </Typography>
          )}
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography variant="body2" color="text.secondary">
              {station?.description}
            </Typography>
          )}
        </Stack>
        <Divider />
        <Stack direction="column" spacing={1}>
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography variant="body2" color="text.secondary">
              {station?.address}
            </Typography>
          )}
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography variant="body2" color="text.secondary">
              {station?.city} - {station?.state}, {station?.zip_code}
            </Typography>
          )}
        </Stack>
        {isStationLoading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={36}
            sx={{
              borderRadius: 4,
            }}
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            sx={{ borderRadius: 4 }}
            onClick={handleOnClickTraceRoute}
          >
            Traçar Rota para a Estação
          </Button>
        )}
        <Divider />
        <Stack direction="column" alignItems="flex-start">
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <LocalPhoneOutlined />
              <Typography variant="body1" color="text.secondary">
                <b>Telefone:</b> {station?.phone}
              </Typography>
            </Stack>
          )}
          {isStationLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            station?.email && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <EmailOutlined />
                <Typography variant="body1" color="text.secondary">
                  <b>E-mail:</b> {station?.email}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
        <Divider />
        {isStationLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} variant="rounded" width="100%" height={200} sx={{ borderRadius: 4 }} />
            ))
          : station?.charging_points.map((chargingPoint) => (
              <ChargingPointItem
                key={chargingPoint.id}
                chargingPoint={chargingPoint}
                onClick={handleOnClickViewChargingPoint}
              />
            ))}
      </Stack>
    </Stack>
  )
}

export default StationPage
