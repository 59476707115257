import React from 'react'

import { Box, Stack, Typography, Divider, Skeleton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Wallet } from '@mui/icons-material'

import { useQueryPaymentCredits, useQueryPaymentHistory } from '@/api/payment/queries'

const WalletTab: React.FC = () => {
  const { data: credits, isLoading: isLoadingCredits } = useQueryPaymentCredits()
  const { data: history, isLoading: isLoadingHistory, isError: isErrorHistory } = useQueryPaymentHistory()

  const getTransactionMethod = (method: string) => {
    switch (method) {
      case 'STRIPE':
        return 'Deposito'
      case 'CREDITS':
        return 'Pagamento'
      default:
        return 'N/A'
    }
  }

  const getTransactionColor = (method: string) => {
    switch (method) {
      case 'STRIPE':
        return 'success'
      case 'CREDITS':
        return 'error'
      default:
        return 'info'
    }
  }

  const getTransactionAmount = (method: string, amount: number) => {
    switch (method) {
      case 'STRIPE':
        return Number(amount).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
      case 'CREDITS':
        return `-${Number(amount).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`
      default:
        return 'N/A'
    }
  }

  return (
    <Stack direction="column" width="100%" spacing={2}>
      {isLoadingCredits ? (
        <Skeleton variant="rectangular" width="100%" height={28} />
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Saldo Atual:
          </Typography>
          <Typography variant="body1">
            {Number(credits?.balance).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }) || 'N/A'}
          </Typography>
        </Stack>
      )}
      <LoadingButton
        startIcon={<Wallet />}
        variant="contained"
        color="primary"
        sx={{ borderRadius: 4 }}
        loading={isLoadingCredits}
        disableElevation
        fullWidth
        disabled
      >
        Depositar
      </LoadingButton>
      <Divider />
      <Stack direction="column" spacing={2} flex={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Histórico de Transações
        </Typography>
        <Stack direction="column" spacing={2} flex={1}>
          {isLoadingHistory ? (
            <Stack spacing={2} width="100%">
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton key={index} variant="rounded" width="100%" height={150} sx={{ borderRadius: 4 }} />
              ))}
            </Stack>
          ) : isErrorHistory ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <Stack direction="column" alignItems="center" spacing={4}>
                <Box component="img" src="/assets/png/server_down.png" width="100%" maxWidth={300} />
                <Stack direction="column" alignItems="center" spacing={1}>
                  <Typography variant="h6" color="textSecondary">
                    Ops! Algo deu errado.
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Não foi possível carregar o histórico de transações.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : history?.length ? (
            history.map((transaction) => (
              <Stack
                key={transaction.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  backgroundColor: 'grey.200',
                  border: '1px solid #e0e0e0',
                  padding: 2,
                  spacing: 2,
                  borderRadius: 4,
                }}
              >
                <Typography variant="body2">
                  {new Date(transaction.created_at).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                  {' às '}
                  {new Date(transaction.created_at).toLocaleString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Typography>
                <Typography variant="body2">{getTransactionMethod(transaction.method)}</Typography>
                <Typography variant="body2" color={getTransactionColor(transaction.method)}>
                  {getTransactionAmount(transaction.method, transaction.amount)}
                </Typography>
              </Stack>
            ))
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <Stack direction="column" alignItems="center" spacing={4}>
                <Box component="img" src="/assets/png/empty.png" width="100%" maxWidth={300} />
                <Stack direction="column" alignItems="center" spacing={1}>
                  <Typography variant="h6" color="textSecondary">
                    Ops! Parece que está vazio.
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Não há transações registradas.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default WalletTab
