import React, { useState } from 'react'

import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Field, Form, Formik, FormikHelpers } from 'formik'

import { Box, Stack, Typography, Link, Alert, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import { useMutationPostAuthRegister } from '@/api/auth/mutations'

import { TextInput } from '@/components/Inputs/Text'
import { MaskedInput } from '@/components/Inputs/Masked'

import { HttpError } from '@/api/http'

export interface RegisterFormData {
  first_name: string
  last_name: string
  cpf: string
  phone: string
  email: string
  password: string
  confirmPassword: string
}

const initialValues: RegisterFormData = {
  first_name: '',
  last_name: '',
  cpf: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const YupSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('O campo de nome é obrigatório'),
  last_name: Yup.string().trim().required('O campo de sobrenome é obrigatório'),
  cpf: Yup.string()
    .trim()
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'O CPF informado é inválido')
    .required('O campo de CPF é obrigatório'),
  phone: Yup.string()
    .trim()
    .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'O telefone informado é inválido')
    .required('O campo de telefone é obrigatório'),
  email: Yup.string().trim().email('O e-mail informado é inválido').required('O campo de e-mail é obrigatório'),
  password: Yup.string().trim().required('O campo de senha é obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'As senhas devem coincidir')
    .required('Confirmação de senha é obrigatória'),
})

const RegisterPage: React.FC = () => {
  const navigate = useNavigate()

  const [registerSuccess, setRegisterSuccess] = useState<string | null>(null)
  const [registerError, setRegisterError] = useState<string | null>(null)

  const { mutateAsync: mutatePostAuthRegister } = useMutationPostAuthRegister()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  const handleOnSubmitRegister = (values: RegisterFormData, { setSubmitting }: FormikHelpers<RegisterFormData>) => {
    const { first_name, last_name, cpf, phone, email, password } = values

    mutatePostAuthRegister({
      first_name,
      last_name,
      cpf,
      phone,
      email,
      password,
    })
      .then(() => {
        setRegisterSuccess('Cadastro realizado com sucesso! Você será redirecionado para a página de login.')

        setTimeout(() => {
          navigate('/auth/login')
        }, 5000)
      })
      .catch((error: HttpError) => {
        setRegisterError(
          error.message || 'Ocorreu um erro inesperado ao tentar fazer o cadastro! Tente novamente mais tarde.'
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={4}>
      <Stack direction="column" alignItems="center" textAlign="center">
        <Typography variant="h6" fontWeight="bold">
          Cadastre-se na MicroRed
        </Typography>
        <Typography variant="subtitle1">Carregue Fácil, Ande Mais!</Typography>
      </Stack>

      <Formik initialValues={initialValues} validationSchema={YupSchema} onSubmit={handleOnSubmitRegister}>
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
              {registerError ? (
                <Alert severity="error" sx={{ width: '100%', alignItems: 'center', borderRadius: 4 }}>
                  {registerError}
                </Alert>
              ) : null}
              {registerSuccess ? (
                <Alert severity="success" sx={{ width: '100%', alignItems: 'center', borderRadius: 4 }}>
                  {registerSuccess}
                </Alert>
              ) : null}

              <Field component={TextInput} placeholder="Digite seu Nome" name="first_name" autoComplete="given-name" />
              <Field
                component={TextInput}
                placeholder="Digite seu Sobrenome"
                name="last_name"
                autoComplete="family-name"
              />
              <Field
                component={TextInput}
                placeholder="Digite seu CPF"
                name="cpf"
                autoComplete="cpf"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    mask: '___.___.___-__',
                    replacement: {
                      _: /[0-9]/,
                    },
                  },
                }}
              />
              <Field
                component={TextInput}
                placeholder="Digite seu Telefone"
                name="phone"
                autoComplete="tel"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    mask: '(__) _____-____',
                    replacement: {
                      _: /[0-9]/,
                    },
                  },
                }}
              />
              <Field
                component={TextInput}
                placeholder="Digite seu E-mail"
                name="email"
                type="email"
                autoComplete="email"
              />
              <Field
                component={TextInput}
                placeholder="Digite sua Senha"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                component={TextInput}
                placeholder="Confirme sua Senha"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                variant="contained"
                color="primary"
                sx={{ borderRadius: 20 }}
                onClick={submitForm}
                loading={isSubmitting}
                disableElevation
                fullWidth
              >
                Registrar
              </LoadingButton>

              <Stack alignItems="center" justifyContent="center">
                <Link href="/auth/login" variant="body2" underline="hover">
                  Já tem uma conta? Entrar
                </Link>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  )
}

export default RegisterPage
