import React, { useState, useEffect } from 'react'

import { useNavigate, Outlet, useLocation } from 'react-router-dom'

import { Box, BottomNavigation, BottomNavigationAction, Stack, CssBaseline } from '@mui/material'
import { Map, EvStation, QrCodeScanner as QrCodeScannerIcon, Wallet, Person } from '@mui/icons-material'

import { BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning'

import { useKeyboard } from '@/contexts/KeyboardContext'

const MainTemplate: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isKeyboardVisible } = useKeyboard()

  const [isSupported, setIsSupported] = useState<boolean>(false)

  const requestPermissions = async (): Promise<boolean> => {
    try {
      const { camera } = await BarcodeScanner.requestPermissions()
      return camera === 'granted' || camera === 'limited'
    } catch {
      return false
    }
  }

  const scan = async () => {
    const granted = await requestPermissions()

    if (!granted) {
      return
    }

    try {
      const { barcodes } = await BarcodeScanner.scan({
        formats: [BarcodeFormat.QrCode],
      })

      if (barcodes.length > 0) {
        const barcode = barcodes[0]

        if (barcode.rawValue) {
          const slug = barcode.rawValue.split('.app').pop()

          if (slug) {
            navigate(slug)
          }
        }
      }
    } catch {
      return
    }
  }

  const handleOnChangeBottomNavigation = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue !== 'scan') {
      navigate(newValue)
    }
  }

  useEffect(() => {
    BarcodeScanner.isSupported()
      .then((result) => {
        setIsSupported(result.supported)
      })
      .catch(() => {
        setIsSupported(false)
      })
  }, [])

  const isMapPage = location.pathname === '/'

  return (
    <Stack>
      <CssBaseline />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginBottom: isMapPage ? 'none' : isKeyboardVisible ? '56px' : '112px',
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Box>

      {!isKeyboardVisible && (
        <Box
          component="footer"
          position="fixed"
          sx={{
            bottom: '56px',
            left: '50%',
            transform: 'translate(-50%, 50%)',
            paddingX: '24px',
            width: '100%',
            zIndex: 1,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
          }}
        >
          <BottomNavigation
            value={location.pathname}
            onChange={handleOnChangeBottomNavigation}
            showLabels
            sx={{
              borderRadius: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(5px)',
            }}
          >
            <BottomNavigationAction
              label="Mapa"
              value="/"
              icon={<Map />}
              disableRipple
              sx={{
                minWidth: '50px',
              }}
            />
            <BottomNavigationAction
              label="Estações"
              value="/stations"
              icon={<EvStation />}
              disableRipple
              sx={{
                minWidth: '50px',
              }}
            />
            <BottomNavigationAction
              value="scan"
              icon={<QrCodeScannerIcon />}
              disableRipple
              sx={{
                color: 'white',
                backgroundColor: isSupported ? 'primary.main' : 'action.disabled',
                borderRadius: '50%',
                minWidth: 'auto',
                width: 44,
                height: 44,
                padding: 0,
                margin: '6px',
                flex: 'none',
                '&.Mui-selected': {
                  color: 'white',
                },
              }}
              onClick={scan}
              disabled={!isSupported}
            />
            <BottomNavigationAction
              label="Carteira"
              value="/wallet"
              icon={<Wallet />}
              disableRipple
              sx={{
                minWidth: '50px',
              }}
            />
            <BottomNavigationAction
              label="Perfil"
              value="/profile"
              icon={<Person />}
              disableRipple
              sx={{
                minWidth: '50px',
              }}
            />
          </BottomNavigation>
        </Box>
      )}
    </Stack>
  )
}

export default MainTemplate
