import http from '@/api/http'

import { GetPaymentCreditsResponse, GetPaymentHistoryResponse } from './types'

export const getPaymentCredits = async (): Promise<GetPaymentCreditsResponse> => {
  const response = await http.get<GetPaymentCreditsResponse>('/payment/credits/')
  return response.data
}

export const getPaymentHistory = async (): Promise<GetPaymentHistoryResponse> => {
  const response = await http.get<GetPaymentHistoryResponse>('/payment/history/')
  return response.data
}
