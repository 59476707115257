import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Stack, Avatar, Typography, Alert, Button, Divider, IconButton, CircularProgress } from '@mui/material'
import {
  Edit,
  BadgeOutlined,
  PermIdentityOutlined,
  PhoneOutlined,
  EmailOutlined,
  DirectionsCarOutlined,
  PaletteOutlined,
  AccessTimeOutlined,
  LogoutOutlined,
} from '@mui/icons-material'

import { useAuth } from '@/hooks/useAuth'

import { useToast } from '@/contexts/ToastContext'

const ProfilePage: React.FC = () => {
  const { show } = useToast()
  const navigate = useNavigate()
  const { user, logout, isLoading: isAuthLoading } = useAuth()

  const handleOnClickLogout = () => {
    logout()
      .then(() => {
        show('Você saiu da sua conta com sucesso!', {
          severity: 'success',
        })

        navigate('/auth')
      })
      .catch(() => {
        show('Ocorreu um erro ao tentar sair da conta!', {
          severity: 'error',
        })
      })
  }

  return (
    <Stack paddingTop="24px" paddingX="24px">
      <Stack
        direction="column"
        spacing={4}
        sx={{
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        <Stack position="relative" direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h6" fontWeight="bold">
            Perfil
          </Typography>
          {isAuthLoading ? (
            <CircularProgress color="error" size={24} sx={{ position: 'absolute', right: 0 }} />
          ) : (
            <IconButton color="error" onClick={handleOnClickLogout} sx={{ position: 'absolute', right: 0 }}>
              <LogoutOutlined />
            </IconButton>
          )}
        </Stack>

        <Stack
          direction="column"
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            alt={user?.name}
            sx={{
              width: '128px',
              height: '128px',
            }}
          />
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">{user?.name}</Typography>
            <Typography variant="body1">Usuário desde 2024</Typography>
          </Stack>
        </Stack>

        {user?.email_verified ? null : (
          <Alert severity="warning" sx={{ borderRadius: 4, alignItems: 'center' }}>
            <Typography variant="body2" fontSize="0.8rem" fontWeight="bold">
              Verifique seu email para confirmar o cadastro.
            </Typography>
          </Alert>
        )}

        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight="bold">
              Informações Pessoais
            </Typography>
            <Button variant="text" startIcon={<Edit />} disabled>
              Editar
            </Button>
          </Stack>
          <Stack direction="column" bgcolor="white" borderRadius={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <PermIdentityOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Nome
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user?.given_name || 'Sem Nome Cadastrado'}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <PermIdentityOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Sobrenome
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user?.family_name || 'Sem Sobrenome Cadastrado'}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <BadgeOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  CPF
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user?.preferred_username || 'Sem CPF Cadastrado'}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <PhoneOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Telefone
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user?.phone || 'Sem Telefone Cadastrado'}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <EmailOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Email
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user?.email || 'Sem Email Cadastrado'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        {/* <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight="bold">
              Informações do Veículo
            </Typography>
            <Button variant="text" startIcon={<Edit />} disabled>
              Editar
            </Button>
          </Stack>
          <Stack direction="column" bgcolor="white" borderRadius={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsCarOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Placa
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                Sem Placa Cadastrada
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <DirectionsCarOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Modelo
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                Sem Modelo Cadastrado
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <PaletteOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Cor
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                Sem Cor Cadastrada
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1.5} spacing={5}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <AccessTimeOutlined fontSize="small" />
                <Typography variant="body1" fontWeight="bold">
                  Ano
                </Typography>
              </Stack>
              <Typography variant="body1" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                Sem Ano Cadastrado
              </Typography>
            </Stack>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  )
}

export default ProfilePage
